import React from "react"
import styled from "styled-components"
import {
  maxWidth,
  minHeight,
  display,
  flexDirection,
  space,
} from "styled-system"

const StyledBox = styled.div`
  ${display};
  ${maxWidth};
  ${minHeight};
  ${flexDirection};
  ${space}
  flex-wrap: wrap;
`

const Columns = props => (
  <StyledBox
    mx={[-2, -3, -4]}
    display="flex"
    flexDirection={["column", "row", "row"]}
    {...props}
  />
)

export default Columns
