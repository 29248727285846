import React from "react"
import styled from "styled-components"
import Box from "@components/styled/Box"
import Select from "react-select"

import { rem } from "@src/theme"

const SelectInput = props => {
  const {
    name,
    options,
    value,
    error,
    onBlur,
    onChange,
    placeholder = "Select an option"
  } = props
  return (
    <Outer>
      <Select
        name={name}
        className="form-select"
        classNamePrefix="form-select"
        value={options ? options.filter(opt => opt.value === value) : ""}
        onChange={option => onChange(name, option.value)}
        options={options}
        placeholder={placeholder}
        onBlur={() => onBlur && onBlur(name, true)}
      />
      {error && (
        <Box as="span" color="red" display="block" mt={2} fontSize={rem(12)}>
          {error}
        </Box>
      )}
    </Outer>
  )
}

export default SelectInput

const Outer = styled.div`
  .form-select {
    > div {
      border-color: #c4c4c4;
      padding: ${rem(5)};
      border-radius: 3px;
    }
  }

  .form-select__placeholder {
    color: #bcbcbc;
  }
`
