import matchSorter from "match-sorter"
import moment from "moment"
export const getValueFromId = (dataArr, recordsFieldArr) => {
  if (recordsFieldArr && recordsFieldArr.length) {
    if (dataArr.filter(({ node }) => node.recordId === recordsFieldArr[0])) {
      return {
        id: dataArr.filter(
          ({ node }) => node.recordId === recordsFieldArr[0]
        )[0].node.recordId,
        name: dataArr.filter(
          ({ node }) => node.recordId === recordsFieldArr[0]
        )[0].node.data.Name
      }
    }
  }

  return ""
}

export const mapOptions = (arr, type = "") =>
  arr
    .map(({ node }) => ({
      value: node.recordId,
      label: node.data.Name,
      type
    }))
    .reverse()

export const fuzzyTextFilterCb = (
  query,
  record,
  recordsData,
  keysToFilterBy
) => {
  return matchSorter(recordsData, query, { keys: [...keysToFilterBy] }).some(
    result => result.name === record.name
  )
}

export const selectOptionFilterCb = (optionId, filterType, record) => {
  return record[filterType].id === optionId
}

export const valueOrNothing = val => {
  if ((val && typeof val === "string") || (val && typeof val === "number")) {
    return val
  }

  if (val && typeof val === "object" && val.length) {
    return val[0]
  }
  if (!val || !val.length) {
    return ""
  }
}

export const formatAirtableDate = date => {
  if (!date) {
    return false
  }
  const elements = date.split("-")
  return moment([elements[2], elements[1], elements[0]].join("/"), "DD/MM/YYYY")
}

/**
 * @todo different functions below for different types, this is getting too weird
 */
export const getFilterCallbacks = (filters, recordsData, textFilterOptions) => {
  const filterCallbacks = []
  filters.forEach(filter => {
    if (filter.value) {
      if (filter.type === "date") {
        filterCallbacks.push(
          record =>
            moment(moment(filter.value).format("YYYY-MM-DD")).isSame(
              record[filter.type]
            ) ||
            moment(record[filter.type]).isBefore(
              moment(filter.value).format("YYYY-MM-DD")
            )
        )
      } else if (filter.type === "attendees") {
        filterCallbacks.push(
          record => parseInt(record[filter.type]) >= parseInt(filter.value)
        )
      } else if (textFilterOptions.indexOf(filter.type) === -1) {
        filterCallbacks.push(record => record[filter.type].id === filter.value)
      } else {
        filterCallbacks.push(record => {
          return matchSorter(recordsData, filter.value, {
            keys: [filter.type]
          }).some(result => result[filter.type] === record[filter.type])
        })
      }
    }
  })

  return filterCallbacks
}

export const getFilterOptions = (filters, filterByOptionData) => {
  const selectedOptions = filters.map(({ type }) => type)
  const options = filterByOptionData.map(option => {
    if (selectedOptions.indexOf(option.value) === -1) {
      return { ...option, isDisabled: false }
    }
    return { ...option, isDisabled: true }
  })
  return options
}

export const getUnselectedFilterOptions = (filters, filterByOptionData) => {
  const selectedOptions = filters.map(({ type }) => type)
  const availableOptions = filterByOptionData.filter(option => {
    if (selectedOptions.indexOf(option.value) === -1) {
      return true
    }
    return false
  })
  return availableOptions
}
