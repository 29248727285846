import React from "react"
import {
  maxWidth,
  minHeight,
  display,
  top,
  position,
  space,
  width,
  right,
} from "styled-system"
import styled from "styled-components"

const StyledBox = styled.div`
  ${width}
  ${maxWidth}
  ${minHeight}
  ${display}
  ${top}
  ${position}
  ${space}
  ${right}
`

const Column = props => <StyledBox px={[2, 3, 4]} {...props} />

export default Column
