import React from "react"
import styled from "styled-components"
import Auth from "@helpers/auth"
import Box from "@components/styled/Box"
import { rem } from "@src/theme"
const auth = new Auth()

const Login = () => {
  const { isAuthenticated } = auth

  if (isAuthenticated()) {
    return (
      <Box display="flex" alignItems="center">
        <LogoutButton onClick={auth.logout}>Logout</LogoutButton>
      </Box>
    )
  } else {
    return <LoginButton onClick={auth.login}>Login</LoginButton>
  }
}

export default Login

export const LoginButton = styled.button`
  background-color: ${p => p.theme.colors.blue};
  color: ${p => p.theme.colors.white};
  font-family: "NeueDisplay";
  border: 1px solid ${p => p.theme.colors.blue};
  border-radius: 100px;
  padding: ${rem(6)} ${rem(25)} ${rem(6)} ${rem(25)};
  text-transform: uppercase;
  font-size: ${rem(12)};
`

export const LogoutButton = styled(LoginButton)`
  border-color: ${p => p.theme.colors.black};
  background-color: transparent;
  color: ${p => p.theme.colors.black};
`
