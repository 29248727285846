import React from "react"
import styled from "styled-components"
import Box from "@components/styled/Box"
import { rem } from "@src/theme"
const TextInput = props => {
  const {
    name,
    type = "text",
    value,
    onChange,
    error,
    onBlur,
    placeholder
  } = props
  return (
    <>
      <Input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        min={type === "number" ? 0 : null}
        placeholder={placeholder}
      />
      {error && (
        <Box as="span" color="red" display="block" mt={2} fontSize={rem(12)}>
          {error}
        </Box>
      )}
    </>
  )
}

export default TextInput

const Input = styled.input`
  border: 1px solid #c4c4c5;
  border-color: #c4c4c4;
  padding: ${rem(5)};
  padding-left: ${rem(12)};
  border-radius: 3px;
  min-height: ${rem(44)};
  font-family: "NeueText";
  font-size: 1rem;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #bcbcbc;
  }
  :-ms-input-placeholder {
    font-size: 100px;
  }
`
