import airtable from "airtable"

export const base = new airtable({ apiKey: process.env.AIRTABLE_API_KEY }).base(
  process.env.AIRTABLE_API_BASE
)

export const fetchEducationSystemPartners = () =>
  base("DL_Education_System_Partner")
    .select({
      // Selecting the first 3 records in Grid view:
      view: "Grid view"
    })
    .all()

export const fetchEvaluationPartners = () =>
  base("DL_Evaluation_Partners")
    .select({
      // Selecting the first 3 records in Grid view:
      view: "Grid view"
    })
    .all()

export const fetchPhilanthropyPartners = () =>
  base("DL_Philanthropy_Partners")
    .select({
      // Selecting the first 3 records in Grid view:
      view: "Grid view"
    })
    .all()

// project stuff
export const fetchProjectTypes = () =>
  base("DL_PROJECT_TYPE")
    .select({
      // Selecting the first 3 records in Grid view:
      view: "Grid view"
    })
    .all()

export const fetchProjects = () =>
  base("Projects")
    .select({
      // Selecting the first 3 records in Grid view:
      view: "Grid view"
    })
    .all()

export const fetchEvents = () =>
  base("Events")
    .select({
      // Selecting the first 3 records in Grid view:
      view: "Grid view"
    })
    .all()
export const fetchArticles = () =>
  base("Articles")
    .select({
      // Selecting the first 3 records in Grid view:
      view: "Grid view"
    })
    .all()
export const fetchProducts = () =>
  base("Products")
    .select({
      // Selecting the first 3 records in Grid view:
      view: "Grid view"
    })
    .all()
