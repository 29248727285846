import React from "react"
import Box from "./Box"

import { settings } from "@src/theme"

const Container = props => (
  <Box maxWidth={settings.maxWidth} mx="auto" {...props} width={"90%"} />
)

export default Container
