import styled from "styled-components"
import {
  space,
  width,
  maxWidth,
  maxHeight,
  color,
  justifyContent,
  alignItems,
  display,
  height,
  flexDirection,
  fontFamily,
  textAlign,
  minHeight,
  buttonStyle,
  fontWeight,
  alignSelf,
  fontSize,
  position,
  flexWrap,
  lineHeight,
  minWidth,
  flex,
  overflow,
  zIndex,
  borderRadius,
  top,
  left,
  right
} from "styled-system"

const StyledBox = styled.div`
    ${space}
    ${width}
    ${maxWidth}
    ${maxHeight}
    ${minHeight}
    ${minWidth}
    ${color}
    ${display}
    ${justifyContent}
    ${alignItems}
    ${height}
    ${flexDirection}
    ${fontFamily}
    ${textAlign}
    ${buttonStyle}
    ${fontWeight}
    ${alignSelf}
    ${fontSize}
    ${flexWrap}
    ${lineHeight}
    ${flex}
    ${overflow}
    ${position}
    ${zIndex}
    ${borderRadius}
    ${top}
    ${left}
    ${right}
`

const Box = styled(StyledBox)``

export default Box
