import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { connect } from "react-redux"
import Box from "@components/styled/Box"
import Container from "@components/styled/Container"
import Login from "@components/Login"

const Layout = ({ children }) => {
  return (
    <>
      <Box>
        <Box as="header">
          <Container>
            <Box
              display="flex"
              justifyContent="space-between"
              mx="auto"
              py={5}
              alignItems="center"
            >
              <Link to="/">Home</Link>
              <Login />
            </Box>
          </Container>
        </Box>
        <Box as="main">{children}</Box>
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  uri: PropTypes.string.isRequired
}

const mapState = state => ({})

const mapDispatch = dispatch => ({})

export default connect(
  mapState,
  mapDispatch
)(Layout)
